import eventModule from '../../../services/event';
const _ = require('lodash');

export default {
  data() {
    return {
      fetchingData: true,
      eventList: null
    };
  },

  methods: {
    fetchEventList() {
      eventModule
        .getEventList(this)
        .then((result) => {
          this.eventList = [];

          Object.keys(result.data.data.eventList).forEach((eventName) => {
            console.log('event name is', eventName);
            if (this.allSystemEvents.indexOf(eventName) >= 0) {
              if (this.eventListPageDefaultEvents.indexOf(eventName) == -1) {
                return;
              }
            }
            this.eventList.push({
              name: eventName,
              properties: result.data.data.eventList[eventName]
            });
          });

          this.eventList = _.sortBy(this.eventList, [
            function (o) {
              return o.name;
            }
          ]);

          this.fetchingData = false;
        })
        .catch(() => {
          this.errorToast('Something went wrong.');
          this.fetchingData = false;
        });
    }
  },
  created() {
    // this.fetchTechnologyTabData();
    this.fetchEventList();
  }
};
