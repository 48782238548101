<template>
  <div id="eventListPage">
    <div class="header">
      All Events
      <div class="subTitle" v-if="!fetchingData && eventList">{{ eventList.length }} Events</div>
    </div>
    <div class="loader" v-if="fetchingData" v-loading="true"></div>
    <el-table v-else :data="eventList" row-class-name="eventRow" header-row-class-name="headerRow" style="width: 100%" border>
      <el-table-column label="Event" prop="name" width="350">
        <template slot-scope="scope">
          <router-link :to="{ name: 'EventDetails', params: { eventName: scope.row.name } }">
            <el-button type="text" style="padding: 0px">
              {{ scope.row.name }}
            </el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Event Properties">
        <template slot-scope="scope">
          <div class="propertyTag" v-for="(property, index) in scope.row.properties" :key="index">
            {{ property.name }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import eventListComponent from './eventListComponent';
export default eventListComponent;
</script>

<style lang="scss" src="./eventList.scss"></style>
